<template>
  <div class="container" style="max-height: 60vh; overflow-y: auto;">
    <div>
      <b-row>
        <b-col>
          <b-form-group label="Question No:" label-for="input-2" description="">
            <b-form-input id="input-2" v-model="question.question_no" type="text" placeholder="1 or 1.1"></b-form-input>
          </b-form-group>
        </b-col>
        <b-col v-if="allowAllQuestionFields">
          <b-form-group label="Maximum Score:" label-for="input-2" description="">
            <b-form-input id="input-2" v-model="question.maximum_mark" type="text" placeholder="10"></b-form-input>
          </b-form-group></b-col>
        <b-col v-if="allowAllQuestionFields">
          <b-form-group label="Type:" label-for="input-2" description="">
            <b-form-select v-model="question.type" :options="questionTypeOptions">
            </b-form-select>
          </b-form-group>
        </b-col>
        <b-col v-if="allowAllQuestionFields">
          <b-form-group label="Difficulty Level:" label-for="input-2" description="">
            <b-form-select v-model="question.difficulty_level" :options="difficultyLevelOptions">
            </b-form-select>
          </b-form-group>
        </b-col>
      </b-row>
      <b-col class="my-2" v-if="(allowAllQuestionFields && !offlineExam)">
        <b-row><b-form-checkbox v-model="question.allow_attachment_answer">Allow Attachment</b-form-checkbox>
          <b-form-checkbox class="ml-3" v-model="question.allow_descriptive">Allow Descriptive
            Answer</b-form-checkbox></b-row></b-col>
      <b-row v-if="allowAllQuestionFields">
        <b-col>
          <b-form-group label="Course Outcomes:" label-for="input-2" description="">
            <multiselect v-model="question.co_mapping" :options="courseOutcomes" :multiple="true"
              :close-on-select="false" :clear-on-select="false" placeholder="Select CO's" label="code" track-by="id">
            </multiselect>
          </b-form-group>
        </b-col>
        <b-col>
          <b-form-group label="Programme Outcomes:" label-for="input-2" description="">
            <multiselect v-model="question.po_mapping" :options="programmeOutcomes" :multiple="true"
              :close-on-select="false" :clear-on-select="false" placeholder="Select PO's" label="code" track-by="id">
            </multiselect>
          </b-form-group>
        </b-col>
        <b-col>
          <b-form-group label="Programme Specific Outcomes:" label-for="input-2" description="">
            <multiselect v-model="question.pso_mapping" :options="programmeSpecificOutcomes" :multiple="true"
              :close-on-select="false" :clear-on-select="false" placeholder="Select PSO's" label="code" track-by="id">
            </multiselect>
          </b-form-group>
        </b-col>
        <b-col>
          <b-form-group label="Module:" label-for="input-2" description="">
            <b-form-select v-model="question.module_id" :options="courseModules" text-field="module" value-field="id">
            </b-form-select>
          </b-form-group></b-col>
        <b-col>
          <b-form-group label="Blooms Taxonomy Level:" label-for="input-2" description="">
            <b-form-select v-model="question.blooms_taxonamy_level" :options="bloomTaxonomyOptions">
            </b-form-select>
          </b-form-group>
        </b-col>
      </b-row>
      <div class="mt-2">
        <div>Question:</div>
        <!-- <math-field id="dummy_mathfield" style="">x=\frac{-b\pm \sqrt{b^2-4ac}}{2a}</math-field>kjbi -->
        <div v-for="(qData, qdIndex) in question.question" :key="qdIndex" class="my-1">
          <b-row>
            <b-col>
              <!-- <b-form-input v-if="qData.type == 'text'" id="input-2" v-model="qData.value" type="text"
                placeholder="Enter Question"></b-form-input> -->
              <jodit-editor v-if="qData.type == 'text'" v-model="qData.value" :extra-buttons="customButtons"
                :config="editorConfig" :buttons="JoditViewFullButtons"/>
              <div v-if="qData.type == 'equation'">
                <math-field v-if="qData.value == ''" :id="('mathfield' + qdIndex)" style="width: 100%;">x=\frac{-b\pm
                  \sqrt{b^2-4ac}}{2a}</math-field>
                <math-field v-else :id="('mathfield' + qdIndex)" style="width: 100%;">{{ qData.value }}</math-field>
              </div>
              <div v-if="qData.type == 'image'">
                <div>
                  <iframe
                    :src="(cloudStorageRoot + questionAttachmentDetails(qData.value).file_path + questionAttachmentDetails(qData.value).file_name)"
                    style="width: 100%; max-height:350px;"></iframe>
                </div>
                <a :href="cloudStorageRoot + questionAttachmentDetails(qData.value).file_path + questionAttachmentDetails(qData.value).file_name"
                  style="font-size: 12px; font-weight: 900; word-break: break-word;" class="text-decoration-none"
                  target="_blank">
                  {{ questionAttachmentDetails(qData.value).file_name }}
                </a>
              </div>
            </b-col>
            <b-col cols="auto" class="ml-0"> <i style="font-size: 12px !important;"
                class="fas fa-trash-alt text-danger text-sm btn p-0 mx-1" @click="removeDataFromQuestion(qdIndex)"></i>
            </b-col>
          </b-row>
        </div>

        <div class="m-1" v-if="!updatingQuestion">
          <b-button variant="outline-success" size="sm" @click="addTextToQuestion()">Add Text</b-button>
          <b-button variant="outline-success" class="ml-1" size="sm" @click="addEquationToQuestion()">Add
            Equation/Formulae(With Keyboard)</b-button>
          <b-button variant="outline-success" class="ml-1" size="sm" @click="addImageToQuestion()">Add Image</b-button>
          <b-button variant="outline-info" class="ml-1" size="sm" v-if="(question.evaluation_scheme === null)"
            @click="question.evaluation_scheme = [{ type: 'text', value: '' }]">Add Evaluation Scheme For This Question</b-button>
        </div>
        <b-form-file :ref="'newQuestionAttachmentInput'" style="display: none;" @change="uploadQuestionAttachments"
          class="text-center" v-model="newQuestionAttAchmentFile"></b-form-file>

        <div class="mt-4" v-if="(question.evaluation_scheme !== null)">
          <div>Evaluation Scheme: </div>

          <div v-for="(qData, qdIndex) in question.evaluation_scheme" :key="qdIndex" class="my-1">
            <b-row>
              <b-col>
                <jodit-editor v-if="qData.type == 'text'" v-model="qData.value" :extra-buttons="customButtons"
                  :config="editorConfig" :buttons="JoditViewFullButtons"/>
                <div v-if="qData.type == 'image'">
                  <div>
                    <iframe
                      :src="(cloudStorageRoot + questionAttachmentDetails(qData.value).file_path + questionAttachmentDetails(qData.value).file_name)"
                      style="width: 100%; max-height:350px;"></iframe>
                  </div>
                  <a :href="cloudStorageRoot + questionAttachmentDetails(qData.value).file_path + questionAttachmentDetails(qData.value).file_name"
                    style="font-size: 12px; font-weight: 900; word-break: break-word;" class="text-decoration-none"
                    target="_blank">
                    {{ questionAttachmentDetails(qData.value).file_name }}
                  </a>
                </div>
              </b-col>
              <b-col v-if="qData.type == 'image'" cols="auto" class="ml-0"> <i style="font-size: 12px !important;"
                  class="fas fa-trash-alt text-danger text-sm btn p-0 mx-1" @click="removeDataFromEvaluationScheme(qdIndex)"></i>
              </b-col>
            </b-row>
          </div>

          <div class="m-1">
            <b-button variant="outline-success" class="" size="sm" @click="addImageToEvaluationScheme()">Add Image To Evaluation Scheme</b-button>
          </div>
        </div>
        <b-form-file :ref="'newEvaluationSchemeAttachmentInput'" style="display: none;" @change="uploadEvaluationSchemeAttachments"
          class="text-center" v-model="newEvaluationSchemeAttAchmentFile"></b-form-file>

        <div class="m-1 text-danger" v-if="updatingQuestion"> Question details update in progress, Please wait...</div>
      </div>
    </div>
    <div class="mt-3 text-right">
      <b-button variant="danger" size="sm" @click="cancelEditQuestion">Cancel</b-button>
      <b-button variant="success" class="ml-5" size="sm" @click="saveQuestion(true)">Save Changes</b-button>
    </div>

  </div>
</template>

<script>
// eslint-disable-next-line no-unused-vars
import {JoditViewFullButtons } from '../../../../constants';
import 'jodit/build/jodit.min.css';
import { JoditEditor } from 'jodit-vue';

import Vue from 'vue';
import * as MathLive from 'mathlive';
Vue.use(MathLive);
export default {
  props: {
    editQuestion: {},
    questionId: {},
    courseId: {},
    finishEditQuestion: Function,
    cancelEditQuestion: Function,
    offlineExam: {}
  },
  async created() {
    this.cloudStorageRoot = this.$store.getters.getAPIKey.userAttachments;
    // this.question = this.editQuestion;
    this.question = {
      allow_attachment_answer: this.editQuestion.allow_attachment_answer != 1 ? false : true,
      allow_descriptive: this.editQuestion.allow_descriptive != 1 ? false : true,
      blooms_taxonamy_level: this.editQuestion.blooms_taxonamy_level,
      // choices: this.editQuestion.choices,
      // course_outcome: this.editQuestion.course_outcome,
      co_mapping: this.editQuestion.course_outcome,
      po_mapping: this.editQuestion.programme_outcome,
      pso_mapping: this.editQuestion.programme_specific_outcome,
      files: this.editQuestion.files,
      id: this.editQuestion.id,
      maximum_mark: this.editQuestion.maximum_mark,
      type: this.editQuestion.type,
      difficulty_level: this.editQuestion.difficulty_level,
      module_id: this.editQuestion.module_id,
      question: this.editQuestion.question,
      question_no: this.editQuestion.question_no,
      summary: this.editQuestion.summary,
      evaluation_scheme: this.editQuestion.evaluation_scheme,
      updated_at: this.editQuestion.updated_at,
      subquestion_parent_id: this.editQuestion.subquestion_parent_id,
      subQuestions: this.editQuestion.subQuestions,
    };
    if (this.question == null && this.questionId != null) {
      // this.getQuestion();
    }
    this.question.question = this.question.question ?? [{ type: 'text', value: '' }];
    this.course_id = this.courseId;
    if (this.course_id == null) {
      // this.getExam();
      // this.course_id = this.exam.course[0].id.....
    }
    Promise.all([
      this.getCourseModules(),
      this.getCourseOutcomes(),
      this.getProgrammeOutcomes(),
      this.getProgrammeSpecificOutcomes(),
    ]);
  },
  computed: {
    allowAllQuestionFields() {
      // question si_no and question text are only used for main question of subquestions

      if (this.question.subquestion_parent_id != null) return true;
      if (this.question.subQuestions != null) {
        return this.question.subQuestions.length > 0 ? false : true;
      }
      return true;
    },
  },
  components: {
    JoditEditor,
  },
  data() {
    return {
      fetchData: false,
      cloudStorageRoot: '',
      question: {},
      courseOutcomes: [],
      programmeOutcomes: [],
      programmeSpecificOutcomes: [],
      courseModules: [],
      bloomTaxonomyOptions: [
        {
          value: null,
          text: "Select Level",
          description: "",
          // disabled: true,
        },
        {
          value: "6",
          text: "Creating",
          description: "User information to create something new",
        },
        {
          value: "5",
          text: "Evaluating",
          description: "Examine information and make judgement.",
        },
        {
          value: "4",
          text: "Analyzing",
          description: "Take apart from known and identify relationships.",
        },
        {
          value: "3",
          text: "Applying",
          description: "Use information in a new (but similar) situation.",
        },
        {
          value: "2",
          text: "Understanding",
          description: "Grasp meaning of instructional materials.",
        },
        {
          value: "1",
          text: "Remembering",
          description: "Recall specific facts.",
        },
      ],
      questionTypeOptions: [
        { value: null, text: 'Choose type of question' }, { value: 'Theory', text: 'Theory' }, { value: 'Problem/Design', text: 'Problem/Design' }
      ],
      difficultyLevelOptions: [
        { value: null, text: 'Choose difficulty' }, { value: 'Easy', text: 'Easy' }, { value: 'Medium', text: 'Medium' }, { value: 'Hard', text: 'Hard' }
      ],

      newQuestionAttAchmentFile: null,
      newEvaluationSchemeAttAchmentFile: null,
      updatingQuestion: false,
      customButtons: [
        {
          name: 'LaTex',
          iconURL: '',
          exec: function (editor) {
            editor.selection.insertHTML('<span> $$E=mc^2$$ </span>');
          }
        }
      ],
      editorConfig: {
        toolbarButtonSize: 'xsmall',
        enter: 'br',
      },
      JoditViewFullButtons:JoditViewFullButtons,
    };
  },
  methods: {
    async uploadQuestionAttachments(event) {
      this.newQuestionAttAchmentFile = event.target.files[0];
      await this.saveQuestion(false);
      this.updatingQuestion = true;
      // let file = event.target.files[0];
      const fd = await new FormData();
      // console.log(this.newQuestionAttAchmentFile);
      await fd.append("attachment", this.newQuestionAttAchmentFile);
      const url =
        this.$store.getters.getAPIKey.examQuestions +
        `/${this.question.id}/attachments`;
      await this.$axios
        .post(url, fd)
        .then(() => {
          this.getQuestion(this.question.id);
        })
        .catch((error) => {
          error;
          this.$toast.error(
            "Failed to attach, please try again"
          );
        });

      this.updatingQuestion = false;
    },

    async uploadEvaluationSchemeAttachments(event) {
      this.newEvaluationSchemeAttAchmentFile = event.target.files[0];
      await this.saveQuestion(false);
      this.updatingQuestion = true;
      // let file = event.target.files[0];
      const fd = await new FormData();
      await fd.append("attachment", this.newEvaluationSchemeAttAchmentFile);
      const url =
        this.$store.getters.getAPIKey.examQuestions +
        `/${this.question.id}/evaluationscheme/attachments`;
      await this.$axios
        .post(url, fd)
        .then(() => {
          this.getQuestion(this.question.id);
        })
        .catch((error) => {
          error;
          this.$toast.error(
            "Failed to attach, please try again"
          );
        });

      this.updatingQuestion = false;
    },
    async saveQuestion(finishEditing) {
      this.updatingQuestion = true;
      let maxQuestionSize = 0;
      this.question.question.forEach((qData, qIndex) => {
        const byteSize = new Blob([qData.value]).size;
        const kilobyteSize = byteSize / 1024;
        maxQuestionSize = kilobyteSize > maxQuestionSize ? kilobyteSize : maxQuestionSize;
        if (qData.type == 'equation') {
          const mf = document.getElementById("mathfield" + qIndex);
          this.question.question[qIndex].value = mf.value;
        }
      });
      if (maxQuestionSize > 120) {
        this.$toast.error(
          "Larger image/file not supported inside text editor, Please use  'Add Image' button to attach images"
        );
        this.updatingQuestion = false;
        return;
      }
      
      // let evaluation_scheme = null;
      // let evaluation_scheme_data = this.$refs['EvaluationSchemeEditor'].getContent();
      // if (evaluation_scheme_data != null && evaluation_scheme_data != '' && evaluation_scheme_data != undefined) {
      //   evaluation_scheme = evaluation_scheme_data;
      // }

      let examQues = await {
        allow_attachment_answer: this.question.allow_attachment_answer,
        allow_descriptive: this.question.allow_descriptive,
        blooms_taxonamy_level: this.question.blooms_taxonamy_level,
        // choices: this.question.choices,
        // course_outcome: this.question.course_outcome,
        // files: this.question.files,
        // id: this.question.id,
        evaluation_scheme: this.question.evaluation_scheme,
        maximum_mark: this.question.maximum_mark,
        type: this.question.type,
        difficulty_level: this.question.difficulty_level,
        module_id: this.question.module_id,
        question: this.question.question,
        question_no: this.question.question_no,
        summary: this.question.summary,
        course_outcomes: this.question.co_mapping.map(obj => obj.id),
        programme_outcomes: this.question.po_mapping.map(obj => obj.id),
        programme_specific_outcomes: this.question.pso_mapping.map(obj => obj.id),
      };
      const url =
        this.$store.getters.getAPIKey.examQuestions + `/${this.question.id}`;
      await this.$axios
        .put(url, examQues)
        .then(() => {
          if (finishEditing) this.finishEditQuestion();
        })
        .catch((error) => {
          error;
        });

      this.updatingQuestion = false;
    },

    // consoleformula() {
    //   const mf = document.getElementById("mathfield");
    //   mf.addEventListener("input", () => this.formula = mf.value);
    //   // mf.executeCommand('speak');
    // },

    async removeDataFromQuestion(index) {
      this.updatingQuestion = true;
      if(this.question.question[index].type == 'image'){
        const url = this.$store.getters.getAPIKey.mainAPI + `/examquestions/attachments/${this.question.question[index].value}`;
        await this.$axios.delete(url).then(() => {
          this.question.question.splice(index, 1); this.$forceUpdate();
        });
      }else{
        this.question.question.splice(index, 1); this.$forceUpdate();
      }
      this.updatingQuestion = false;
    },
    async removeDataFromEvaluationScheme(index) {
      this.updatingQuestion = true;
      if(this.question.evaluation_scheme[index].type == 'image'){
        const url = this.$store.getters.getAPIKey.mainAPI + `/examquestions/attachments/${this.question.evaluation_scheme[index].value}`;
        await this.$axios.delete(url).then(() => {
          this.question.evaluation_scheme.splice(index, 1); this.$forceUpdate();
        });
      }else{
        this.question.evaluation_scheme.splice(index, 1); this.$forceUpdate();
      }
      this.updatingQuestion = false;
    },
    addTextToQuestion() {
      this.question.question.push({ type: 'text', value: '' }); this.$forceUpdate();
    },
    addEquationToQuestion() {
      this.question.question.push({ type: 'equation', value: '' }); this.$forceUpdate();
      // let lastIndex = this.question.question.length - 1;
      // setTimeout(() => {
      //   const mf = document.getElementById("mathfield" + lastIndex);
      //   mf.addEventListener("input", () => this.question.question[lastIndex] = mf.value);
      // }, 500);
      // this.$forceUpdate();
    },
    addImageToQuestion() {
      // this.question.question.push({ type: 'image', value: '' }); this.$forceUpdate();
      this.$refs['newQuestionAttachmentInput'].$refs.input.click();
    },
    addImageToEvaluationScheme() {
      this.$refs['newEvaluationSchemeAttachmentInput'].$refs.input.click();
    },


    async getQuestion(questionId) {
      // this.fetchData = true;
      const url =
        this.$store.getters.getAPIKey.mainAPI +
        `/examquestions/${this.questionId == null ? questionId : this.questionId}`;
      await this.$axios.get(url).then((response) => {
        let question = response.data;


        this.question = {
          allow_attachment_answer: question.allow_attachment_answer != 1 ? false : true,
          allow_descriptive: question.allow_descriptive != 1 ? false : true,
          blooms_taxonamy_level: question.blooms_taxonamy_level,
          // choices: question.choices,
          // course_outcome: question.course_outcome,
          evaluation_scheme: question.evaluation_scheme,
          difficulty_level: question.difficulty_level,
          type: question.type,
          co_mapping: question.course_outcome,
          po_mapping: question.programme_outcome,
          pso_mapping: question.programme_specific_outcome,
          files: question.files,
          id: question.id,
          maximum_mark: question.maximum_mark,
          module_id: question.module_id,
          question: question.question,
          question_no: question.question_no,
          summary: question.summary,
          updated_at: question.updated_at,
        };

      });
    },

    // async getExam() {
    //   // this.fetchData = true;
    //   let courseModuleUrl = this.$store.getters.getAPIKey.getCourseModules;
    //   const url = courseModuleUrl.replace("course_id", this.exam.course[0].id);
    //   await this.$axios.get(url).then((response) => {
    //     this.courseModules = response.data;
    //   });
    // },

    async getCourseModules() {
      // this.fetchData = true;
      let courseModuleUrl = this.$store.getters.getAPIKey.getCourseModules;
      const url = courseModuleUrl.replace("course_id", this.course_id);
      await this.$axios.get(url).then((response) => {
        this.courseModules.push({
          id: null, name: 'Select Module'
        });
        this.courseModules = response.data;
      });
    },
    async getCourseOutcomes() {
      let Url = this.$store.getters.getAPIKey.getCourseOutcomes;
      const url = Url.replace("course_id", this.course_id);
      await this.$axios.get(url).then((response) => {
        this.courseOutcomes = response.data;
      });
    },
    async getProgrammeOutcomes() {
      let url = this.$store.getters.getAPIKey.programOutcome + `?course=${this.course_id}`;
      await this.$axios.get(url).then((response) => {
        this.programmeOutcomes = response.data;
      });
    },
    async getProgrammeSpecificOutcomes() {
      let url = this.$store.getters.getAPIKey.programSpecificOutcome + `?course=${this.course_id}`;
      await this.$axios.get(url).then((response) => {
        this.programmeSpecificOutcomes = response.data;
      });
    },
    questionAttachmentDetails(attachmentId) {
      return this.question.files.find(item => item.id == attachmentId);
    }
  }
};
</script>

<style>
a.jodit-status-bar-link {
  display: none;
}
</style>
<style scoped>
/* .wysiwyg-math-field{
  background-color: #787878;color: #ffeecf; margin: 0px 5px 0px 5px;
} */
/* .ve-toolbar div {
    padding: 5px !important;
} */
</style>

<style >
.questions-container button {
  padding: 1px !important;
}

.ML__keyboard {
  z-index: 1200 !important;
}

#mathlive-suggestion-popover {
  z-index: 1200 !important;
}
</style>
